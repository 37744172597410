<template>
  <el-card class="created-store">
    <el-page-header
      class="product-header"
      @back="goBack"
      content="创建店铺"
    ></el-page-header>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      label-position="left"
    >
      <el-form-item label="店铺名称" prop="_name">
        <el-input v-model="ruleForm._name" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="店铺标识ID" prop="storeFlag">
        <el-input
          v-model="ruleForm.storeFlag"
          placeholder="请用英文字母+数字自定义前五位,如yhl001。设置后点击生成ID"
          style="width: 450px"
        ></el-input>
        <el-button type="primary" @click="storeFlag" plain
          >生成店铺标识ID</el-button
        >
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="ruleForm.describe" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="门店图">
        <input type="file" :disabled="disabledImage" @change="onchangemd" />
        <div class="files_list">
          <div
            class="files_list_childer"
            v-for="(item, index) in ruleForm.imageInfo"
            :key="index"
          >
            {{ item.imageName }}
            <span @click="files_D(item)" class="files_D el-icon-close"></span>
          </div>
        </div>
        <div class="imgList">
          <div
            class="img-list-div"
            v-for="(item, index) in ruleForm.imageInfo"
            :key="index"
          >
            <span @click="image_D(item)" class="icon el-icon-error"></span>
            <img :src="item.imageUrl" alt="" />
          </div>
        </div>
      </el-form-item>
      <el-form-item label="门店地址" prop="site">
        <el-input v-model="ruleForm.site" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="营业时间">
        <el-time-select
          placeholder="起始时间"
          v-model="ruleForm.startTime"
          :picker-options="{
            start: '06:30',
            step: '00:15',
            end: '23:30',
          }"
        >
        </el-time-select>
        <span> - </span>
        <el-time-select
          placeholder="结束时间"
          v-model="ruleForm.endTime"
          :picker-options="{
            start: '06:30',
            step: '00:15',
            end: '23:30',
            minTime: ruleForm.startTime,
          }"
        >
        </el-time-select>
      </el-form-item>
      <el-form-item label="营业额">
        <el-input v-model="ruleForm.turnover" style="width: 400px"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="ruleForm.phone"
          style="width: 400px"
        ></el-input> </el-form-item
      ><el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >立即创建</el-button
        >
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>
<script>
import secret from "@/utils/secret.js";
import axios from "axios";
export default {
  beforeRouteEnter(to, from, next) {
    const user_key = secret.Decrypt(localStorage.getItem("user_key"));
    console.log(user_key);
    if (user_key !== "yhl001656847198012") {
      return next("/homepage");
    } else {
      next();
    }
  },
  inject: ["reload"],
  data() {
    return {
      ruleForm: {
        _name: "",
        storeFlag: "",
        describe: "",
        site: "",
        turnover: "",
        phone: "",
        startTime: "",
        endTime: "",
        imageInfo: [],
      },
      stopState: false,
      disabledImage: false,
      rules: {
        _name: [
          { required: true, message: "请输入店铺名称", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        storeFlag: [
          { required: true, message: "请输入前五位店铺标识", trigger: "blur" },
        ],
        site: [{ required: true, message: "请输入店铺地址", trigger: "blur" }],
        phone: [{ required: true, message: "请输入手机号码", trigger: "blur" }],
      },
    };
  },
  methods: {
    goBack() {
      this.$router.push("/homepage");
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    storeFlag() {
      if (this.ruleForm.storeFlag !== "") {
        this.ruleForm.storeFlag = this.ruleForm.storeFlag + Date.now() + "SE";
        this.stopState = true;
      } else {
        this.$message({
          type: "error",
          message: "请先自定义店铺标识前五位后再点击生成店铺标识！",
        });
      }
    },
    image_D(e) {
      this.ruleForm.imageInfo = this.ruleForm.imageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    files_D(e) {
      this.ruleForm.imageInfo = this.ruleForm.imageInfo.filter((item) => {
        return item.id !== e.id;
      });
    },
    onchangemd(e) {
      this.disabledImage = true;
      const files = e.target.files[0];
      const isLt5M = +files.size > 5242880 ? true : false;
      if (isLt5M) {
        this.$message({
          type: "worning",
          message: "图片大小不允许大于5M！",
        });
        this.disabledImage = false;
      } else if (
        files.type === "image/png" ||
        files.type === "image/jpeg" ||
        files.type === "image/jpg"
      ) {
        let formdata = new FormData();
        formdata.append("uploadFile", files);
        axios({
          method: "post",
          url: this.GLOBAL.file,
          data: formdata,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then((res) => {
          this.ruleForm.imageInfo.push({
            id: this.ruleForm.imageInfo.length,
            imageName: files._name,
            imageUrl: this.GLOBAL.file + res.data.split("upload")[1],
          });
        });
        this.$message({
          type: "success",
          message: "上传成功",
        });
        this.disabledImage = false;
      } else {
        this.$message({
          type: "worning",
          message: "图片格式不正确",
        });
        this.disabledImage = false;
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.stopState) {
            console.log("this is success!", this.ruleForm);
            let create = true;
            const store = this.ruleForm;
            axios
              .post(this.GLOBAL.api + "/store", { create, store })
              .then((res) => {
                if (res.status === 200 && res.data.statusCode === 200) {
                  this.$message({
                    type: "success",
                    message: "店铺创建成功！",
                  });
                  this.reload();
                } else {
                  this.$message({
                    type: "error",
                    message: "店铺创建失败，请检查重试！",
                  });
                }
              });
          } else {
            this.$message({
              type: "error",
              message: "请点击【生成店铺标识ID】按钮后再创建！",
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style scoped>
.created-store {
  width: 90%;
  height: 95%;
  padding: 20px;
}
.product-header {
  margin-bottom: 20px;
}

.img-list-div {
  display: flex;
}
.img-list-div img {
  width: 300px;
  margin-right: 10px;
}
</style>